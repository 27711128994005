import React, {useEffect} from "react";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";
import Copyright from "../Copirigth/Copyright";
import Form from "./Form/Form"

export default function Contact(){

    useEffect(()=>{
        window.scroll(0,0)
    },[])
    
    return(
        <div>
            <Nav/>
            <Form/>
            <Footer/>
            <Copyright/>
        </div>
    )
}