import React from "react";
import './River_3Port.css'


export default function River_3Port(){
    return(
        <div className="River_3Port_Container">
            <div>
                <h2>COLLON CURA</h2>
                <div className="River_3Port_Line"></div>
            </div>
            <p> The best section of the lower Collon Cura River.</p>
        </div>
    )
}