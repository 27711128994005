import React, {useEffect} from "react";
import Nav from "../../Nav/Nav";
import River_3Port from "./River_3Port/River_3Port";
import RedirectContact from "../../Home/RedirectContact/RedirectContact";
import Footer from "../../Footer/Footer";
import Copyright from "../../Copirigth/Copyright";
import River_3Description from "./River_3Description/River_3Description";

import Img1 from '../../../assets/lodges/collon_cura/outside.jpg'
import Img2 from '../../../assets/lodges/collon_cura/outside_2.jpg'
import Img3 from '../../../assets/lodges/collon_cura/outside_3.jpg'
import Img4 from '../../../assets/lodges/collon_cura/living_4.jpg'
import Img5 from '../../../assets/lodges/collon_cura/food_2.jpg'
import Img6 from '../../../assets/lodges/collon_cura/food.jpg'
import Img7 from '../../../assets/lodges/collon_cura/living_2.jpg'
import Img8 from '../../../assets/lodges/collon_cura/living_3.jpg'
import Img9 from '../../../assets/lodges/collon_cura/living_4.jpg'
import Img10 from '../../../assets/lodges/collon_cura/outside_5.jpg'
import Img11 from '../../../assets/lodges/collon_cura/outside_6.jpg'
import Photo from "../../Home/Photos/Photos";

export default function River_3(){

    
    const arrImg = [Img1,Img2,Img3,Img4,Img5,Img6,Img7,Img8,Img9,Img10,Img11] 

    useEffect(()=>{
        window.scroll(0,0)
    },[])

    return(
        <div>
            <Nav/>

            <River_3Port/>
            <RedirectContact/>
            <River_3Description/>
            <Photo arrImg={arrImg}/>

            <Footer/>
            <Copyright/>
        </div>
    )
}