import React, {useEffect} from "react";
import Nav from "../../Nav/Nav";
import River_1Port from "./River_1Port/River_1Port";
import RedirectContact from "../../Home/RedirectContact/RedirectContact";
import Footer from "../../Footer/Footer";
import Copyright from "../../Copirigth/Copyright";
import River_1Description from "./River_1Description/River_1Description";

import Img1 from '../../../assets/lodges/sanhumbberto/bar.jpg'
import Img2 from '../../../assets/lodges/sanhumbberto/living.jpg'
import Img3 from '../../../assets/lodges/sanhumbberto/living_1.jpg'
import Img4 from '../../../assets/lodges/sanhumbberto/living_2.jpg'
import Img5 from '../../../assets/lodges/sanhumbberto/room.jpg'
import Img6 from '../../../assets/lodges/sanhumbberto/room_3.jpg';
import Img7 from '../../../assets/lodges/sanhumbberto/room2.jpg';
import Img8 from '../../../assets/lodges/sanhumbberto/fire.jpg';
import Img9 from '../../../assets/lodges/sanhumbberto/landscape.jpg';
import Photo from "../../Home/Photos/Photos";

export default function River_1(){

    
    const arrImg = [Img1,Img2,Img3,Img4,Img5,Img6] 

    useEffect(()=>{
        window.scroll(0,0)
    },[])

    return(
        <div>
            <Nav/>

            <River_1Port/>
            <RedirectContact/>
            <River_1Description/>
            <Photo arrImg={arrImg}/>

            <Footer/>
            <Copyright/>
        </div>
    )
}