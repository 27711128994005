import { BrowserRouter, Route,  Routes } from "react-router-dom";
import React, { useState } from "react";
import Home from "./Components/Home/Home";
import ModalPhoto from "./Components/Home/Photos/ModalPhoto/ModalPhoto";
import Nav from "./Components/Nav/Nav";
import Lodges from "./Components/Lodges/General/Lodges";
import River_1 from "./Components/Lodges/River_1/River_1";
import River_2 from "./Components/Lodges/River_2/River_2.jsx"
import River_3 from "./Components/Lodges/River_3/River_3";
import River_4 from "./Components/Lodges/River_4/River_4";
import River_Limai from "./Components/Rivers/River_Limai/River_Limai";
import River_Chimeuin from "./Components/Rivers/River_Chimeuin/River_Chimeuin";
import River_Chimei from "./Components/Rivers/River_Chimei/River_Chimei";
import River_X from "./Components/Rivers/River_X/River_X";
import Gallery from "./Components/Gallery/Gallery";
import Contact from "./Components/Contact/Contact";
import River_5 from "./Components/Lodges/River_5/River_5";
import Error404 from "./Components/Erro404/Error404";

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home/>}/>

        {/*LODGES*/}
        <Route exact path="/Lodges" element={<Lodges/>}/>
        <Route exact path="/Lodges/SanHubberto" element={<River_1/>}/>
        <Route exact path="/Lodges/TresRios" element={<River_2/>}/>
        <Route exact path="/Lodges/CollonCura" element={<River_3/>}/>
        <Route exact path="/Lodges/Tipiliuke" element={<River_4/>}/>
        <Route exact path="/Lodges/RiverHouse" element={<River_5/>}/>

        {/*RIVERS*/}
        <Route exact path="/Rivers/Limai" element={<River_Limai/>}/>
        <Route exact path="/Rivers/Chimeuin" element={<River_Chimeuin/>}/>
        <Route exact path="/Rivers/CollonCura" element={<River_Chimei/>}/>
        <Route exact path="/Rivers/Malleo" element={<River_X/>}/>

        {/*GALLERY*/}
        <Route exact path="/Gallery" element={<Gallery/>}/>

        {/*CONTACT*/}
        <Route exact path="/Contact" element={<Contact/>}/>

        <Route path="*" element={<Error404/>}/>
        
      </Routes>
    </BrowserRouter>
  );
}

export default App;
