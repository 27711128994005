import React, {useEffect} from "react";
import Nav from "../../Nav/Nav";
import River_4Port from "./River_4Port/River_4Port";
import RedirectContact from "../../Home/RedirectContact/RedirectContact";
import Footer from "../../Footer/Footer";
import Copyright from "../../Copirigth/Copyright";
import River_4Description from "./River_4Description/River_4Description";

import Img1 from '../../../assets/lodges/tipileuken//living.jpg'
import Img2 from '../../../assets/lodges/tipileuken/living_1.jpg'
import Img3 from '../../../assets/lodges/tipileuken/room_1.jpg'
import Img4 from '../../../assets/lodges/tipileuken/room_2.jpg'
import Img5 from '../../../assets/lodges/tipileuken/room_3.jpg'
import Img6 from '../../../assets/lodges/tipileuken/room.jpg'
import Img7 from '../../../assets/lodges/tipileuken/bar.jpg'
import Img8 from '../../../assets/lodges/tipileuken/bathroom.jpg'
import Img9 from '../../../assets/lodges/tipileuken/room.jpg'
import Img10 from '../../../assets/lodges/tipileuken/outside_2.jpg'
import Img11 from '../../../assets/lodges/tipileuken/outside_3.jpg'
import Img12 from '../../../assets/lodges/tipileuken/outside_5.jpg'
import Img13 from '../../../assets/lodges/tipileuken/outside_6.jpg'
import Photo from "../../Home/Photos/Photos";

export default function River_4(){

    
    const arrImg = [Img1,Img2,Img3,Img4,Img5,Img6,Img7,Img8,Img9,Img10,Img11,Img12, Img13] 

    useEffect(()=>{
        window.scroll(0,0)
    },[])

    return(
        <div>
            <Nav/>

            <River_4Port/>
            <RedirectContact/>
            <River_4Description/>
            <Photo arrImg={arrImg}/>

            <Footer/>
            <Copyright/>
        </div>
    )
}