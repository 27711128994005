import React from "react";
import './DescriptionLodge.css';


export default function DescriptionLodge(){

    

    return(
        <div className="DescriptionLodge_Container">
            <h2>Lodges patagonicos</h2>
            <div className="DescriptionLodge_Line">/ / / / / </div>
            <p>At Patagonia Paradise, we believe in the importance of sustainability and respect for the environment. Our accommodations are located in privileged areas, where you can appreciate the natural beauty and connect with nature in its purest state. We strive to minimize our impact and promote responsible practices to preserve these natural environments for future generations.

            Whether you are traveling alone, as a couple, with friends, or family, our accommodations in Patagonia will provide you with the comfort and tranquility you need to relax and recharge after your fishing adventures.</p>
            <h3>Regional cuisine</h3>
            <p>At Patagonia Paradise, we value the richness of regional cuisine and strive to offer you an exceptional gastronomic experience. Our restaurants and dining areas are designed to provide a cozy and charming atmosphere, where you can savor each flavor in the company of fellow food enthusiasts.</p>
        </div>
    )
}