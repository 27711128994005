import React from "react";
import './Copyright.css'

export default function Copyright(){
    return(
        <div className="Copyright_Container">
            <p> &#169; Copyright 2023 Fly Fishing| All Rights Reserved | Site Built By <a href="https://www.linkedin.com/in/agustin-david-benitez/">Agustin Benitez</a></p>
            <div className="Copyright_Social">
                <a href=""><i class='bx bxl-instagram Copyright_Icon'></i></a>
                <a href="https://wa.me/+5492972470430"><i class='bx bxl-whatsapp Copyright_Icon' ></i></a>
                <a href=""><i class='bx bxl-facebook-circle Copyright_Icon' ></i></a>
            </div>
        </div>
    )
}