import React, { useState } from "react";
import Icon from '../../assets/icon.svg'
import { Link, useActionData } from "react-router-dom";
import './Nav.css'

export default function Nav(){

    const [activeMenu, setActiveMenu] = useState(false)

    function setMenu(){
        setActiveMenu(!activeMenu)
    }

    const URLactual = window.location.pathname;

    function scrollUp(){
        window.scroll(0,0)
    }

    return(
        <div className="Nav_Container">

            <div className="Nav_ContainerLogo">
                <Link to='/'>
                    <img src={Icon} alt="Icon fly Fishing" className="Nav_Logo" onClick={()=>scrollUp()}/>
                </Link>
            </div>

            <div className="Nav_Menu">
                <label id="Nav_LabelIconMenu">
                    <i class='bx bx-menu Nav_IconMenu' onClick={()=>setMenu()}></i>
                </label>
                <nav className="Nav_Nav">
                    <ul className={activeMenu? "Nav_ContentPrimary": "Nav_ContentPrimary Nav_Inactive"}>
                        <li className="Nav_Li"><Link className={URLactual.includes('/Lodges')? "Nav_LinkPrimary Nav_Select" : "Nav_LinkPrimary"} to='/Lodges'>Lodge</Link>
                            <ul className="Nav_Submenu">
                                <li className="Nav_Li"><Link className="Nav_LinkSecundary" to='/Lodges/SanHubberto'>SAN HUBERTO</Link></li>
                                <li className="Nav_Li"><Link className="Nav_LinkSecundary" to='/Lodges/TresRios'>Tres Rios</Link></li>
                                <li className="Nav_Li"><Link className="Nav_LinkSecundary" to='/Lodges/RiverHouse'>river house</Link></li>
                                <li className="Nav_Li"><Link className="Nav_LinkSecundary" to='/Lodges/Tipiliuke'>tipiliuke</Link></li>
                                <li className="Nav_Li"><Link className="Nav_LinkSecundary" to='/Lodges/CollonCura'>collon cura</Link></li>
                            </ul>
                        </li>
                        <li className="Nav_Li"><Link className={URLactual.includes('/Rivers')? "Nav_LinkPrimary Nav_Select" : "Nav_LinkPrimary"}>Rivers</Link>
                            <ul className="Nav_Submenu">
                                {/* <h3 className="Nav_SubtitleSection">LOdge Chimeuin</h3> */}
                                <li className="Nav_Li"><Link className="Nav_LinkSecundary" to='/Rivers/Limai'>Limay</Link></li>
                                <li className="Nav_Li"><Link className="Nav_LinkSecundary" to='/Rivers/Chimeuin'>Chimeuin</Link></li>
                                <li className="Nav_Li"><Link className="Nav_LinkSecundary" to='/Rivers/CollonCura'>collon cura</Link></li>
                                <li className="Nav_Li"><Link className="Nav_LinkSecundary" to='/Rivers/Malleo'>Malleo</Link></li>
                            </ul>
                        </li>
                        {/* <li className="Nav_Li"><Link className={URLactual.includes('/Hunting')? "Nav_LinkPrimary Nav_Select" : "Nav_LinkPrimary"}>Hunting</Link>
                            <ul className="Nav_Submenu">
                                <h3 className="Nav_SubtitleSection">Hunting 2</h3>
                                <li className="Nav_Li"><Link className="Nav_LinkSecundary">Hunting River</Link></li>
                                <h3 className="Nav_SubtitleSection">Hunting 1</h3>
                                <li className="Nav_Li"><Link className="Nav_LinkSecundary">Hunting River</Link></li>
                            </ul>
                        </li> */}
                        <li className="Nav_Li"><Link className={URLactual.includes('/Gallery')? "Nav_LinkPrimary Nav_Select" : "Nav_LinkPrimary"} to="/Gallery">Gallery</Link></li>
                        <li className="Nav_Li"><Link className={URLactual.includes('/Contact')? "Nav_LinkPrimary Nav_Select" : "Nav_LinkPrimary"} to="/Contact">Contact Us</Link></li>
                    </ul>
                </nav>
            </div>
        </div>
    )
}
