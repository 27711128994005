import React, { useState } from "react";
import './Photos.css';
import ModalPhoto from "./ModalPhoto/ModalPhoto";

export default function Photo({arrImg, color}){

    const [imgModal, setImgModal]= useState('')
    const [activeModal, setActiveModal] = useState(false)

    function OpenModal(e){
        setImgModal(e)
        setActiveModal(true)
    }

    function CloseModal(){
        setActiveModal(false)
        setImgModal('')
    }

    

    return(
        <div className={`Photo_Container ${color}`}>
            {
                activeModal &&
                <ModalPhoto actualPosition={imgModal} close={CloseModal} arrImg={arrImg}/>
            }
            <h2>Gallery</h2>
            <div className="Photo_Line"></div>
            <div className="Photo_ImgContainer">
                    <div className="Photo_ImgCard" onClick={()=>OpenModal(arrImg.indexOf(arrImg[0]))}>
                        <img src={arrImg[0]} alt={arrImg[0]} className="Photo_Img"/>
                        <i class='bx bx-image'></i>
                    </div>
                    <div className="Photo_ImgCard" onClick={()=>OpenModal(arrImg.indexOf(arrImg[1]))}>
                        <img src={arrImg[1]} alt={arrImg[1]} className="Photo_Img"/>
                        <i class='bx bx-image'></i>
                    </div>
                    <div className="Photo_ImgCard" onClick={()=>OpenModal(arrImg.indexOf(arrImg[2]))}>
                        <img src={arrImg[2]} alt={arrImg[2]} className="Photo_Img"/>
                        <i class='bx bx-image'></i>
                    </div>
                    <div className="Photo_ImgCard" onClick={()=>OpenModal(arrImg.indexOf(arrImg[3]))}>
                        <img src={arrImg[3]} alt={arrImg[3]} className="Photo_Img"/>
                        <i class='bx bx-image'></i>
                    </div>
                    <div className="Photo_ImgCard" onClick={()=>OpenModal(arrImg.indexOf(arrImg[4]))}>
                        <img src={arrImg[4]} alt={arrImg[4]} className="Photo_Img"/>
                        <i class='bx bx-image'></i>
                    </div>
                    <div className="Photo_ImgCard" onClick={()=>OpenModal(arrImg.indexOf(arrImg[5]))}>
                        <img src={arrImg[5]} alt={arrImg[5]} className="Photo_Img"/>
                        <i class='bx bx-image'></i>
                    </div>
                {/* {
                    arrImg.map((e)=>{
                        return(
                            <div className="Photo_ImgCard" onClick={()=>OpenModal(arrImg.indexOf(e))}>
                                <img src={e} alt="" className="Photo_Img"/>
                                <i class='bx bx-image'></i>
                            </div>
                        )
                    })
                } */}
            </div>
        </div>
    )
}