import React from "react";
import './River_LimaiPort.css'


export default function River_LimaiPort(){
    return(
        <div className="River_LimaiPort_Container">
            <p>Fly Fishing in</p>
            <div>
                <div className="River_LimaiPort_Line"></div>
                <h2>Limay River</h2>
            </div>
        </div>
    )
}
