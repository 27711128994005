import React from "react";
import './River_ChimeuinDescription.css';

export default function River_ChimeuinDescription(){

    return(
        <div className="River_ChimeuinDescription_Container">

            <div className="River_ChimeuinDescription_SectionOne">
                <h2>Welcome to the Chimeuin River</h2>
                <div className="River_ChimeuinDescription_Bars">/ / / / /</div>
                <p>The Chimehuín River is one of the most prominent rivers in the Patagonia region of Argentina. Located in the province of Neuquén, it flows from Lake Huechulafquen and runs through a breathtaking landscape until it meets the Aluminé River. This river is considered a true paradise for sport fishing enthusiasts.<br/>

                The Chimehuín River is famous for its incredible abundance of trout, particularly rainbow trout and brown trout. These species are highly prized for their size, beauty, and the thrilling battle they provide once hooked. Trout fishing in the Chimehuín River is a unique and challenging experience that captivates anglers from around the world.<br/>

                In addition to trout, the Chimehuín River is also home to other fish species such as perch, American perch, and silverside. The diversity of species offers anglers a variety of options and challenges, making fishing in the Chimehuín River even more exciting and rewarding.<br/>

                The natural surroundings of the Chimehuín River are simply spectacular. Anglers are enveloped by the majesty of the snow-capped peaks of the Andes, lush forests, and pristine meadows as they enjoy their fishing day. The scenic beauty of the Chimehuín River creates a tranquil and serene environment, perfect for those seeking to escape the daily routine and connect with nature.</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus, porro sunt praesentium eum veniam suscipit. Dicta quo odit dolor porro rerum nesciunt atque quibusdam perspiciatis perferendis reprehenderit necessitatibus, impedit ex nostrum ullam facere officia quas soluta nemo eum consequuntur harum. Dolor dolorum pariatur officiis, amet distinctio dolorem, voluptatem ratione quod, in fugit qui quisquam atque sapiente officia alias sint ea vitae maiores? Blanditiis voluptatibus hic veritatis explicabo optio cum in, fugit quisquam doloribus dolore adipisci voluptate expedita maiores repellendus ab aspernatur eligendi suscipit ipsa autem, quia cupiditate distinctio. Veritatis sapiente sit porro vero illum, repudiandae dignissimos ratione quas eveniet quidem?</p>

            </div>

            {/* <div className="River_ChimeuinDescription_LineV"></div> */}

            <div className="River_ChimeuinDescription_SectionTwo">
                <h2>HIGHLIGHTS Programs</h2>
                <div className="River_ChimeuinDescription_Bars">///</div>

                <div className="River_ChimeuinDescription_SectionTwo_Section">
                    <h3>location</h3>
                    <p>The Chimehuín River originates from Lake Huechulafquen and flows for 53 kilometers until it meets the Collón Cura River.</p>
                </div>
                
                <div></div>

                <div className="River_ChimeuinDescription_SectionTwo_Section">
                    <h3>OTHER INFORMATION</h3>
                    <p>In addition, unlike other lakes and rivers in the region, fly fishing is the only permitted method on the Chimehuín River, including three modalities: spinning, fly casting, and trolling.<br/>
                    But the Chimehuín River is not just a fishing paradise. Nestled against the magnificent Lanín Volcano, it is a chosen destination for locals and tourists for recreation in the summer. Both the beaches located a few meters from the center of Junín and the campgrounds beyond the city, with their lush lawns and thick groves of trees, offer an ideal place to sunbathe or find shelter in the shade while simply observing the landscape.
                    </p>
                </div>

                <div></div>

                
            </div>
        </div>
    )
}