import React from "react";
import './River_4Port.css'


export default function River_4Port(){
    return(
        <div className="River_4Port_Container">
            <div>
                <h2>tipiliuke</h2>
                <div className="River_4Port_Line"></div>
            </div>
            <p>Fly fishing for brown and rainbow trout in the Chimehuín river</p>
        </div>
    )
}