import React, { useState } from "react";
import './ModalPhoto.css'

export default function ModalPhoto({actualPosition, arrImg, close}){


    const [position, setPosition] = useState(actualPosition)

    function NextImg(e, state){
            if(e == 1 && state+1 < arrImg.length){
                console.log(e, position)
                return setPosition(state+1)
            }
            if(e == 0 && state > 0){
                console.log(e, position)
                return setPosition(state-1)
            }
    }
    
    return(
        <div className="ModalPhoto_Container">
            <button className="ModalPhoto_ButtonPrev" onClick={()=>NextImg(0, position)}><i class='bx bx-chevron-left'></i></button>
            <div className="ModalPhoto_Close" onClick={()=>close()}>
                <img src={arrImg[position]} alt=""/>
            </div>
            <button className="ModalPhoto_ButtonNext" onClick={()=>NextImg(1, position)}><i class='bx bx-chevron-right' ></i></button>
        </div>
    )
}