import React from "react";
import './River_ChimeiPort.css'


export default function River_ChimeiPort(){
    return(
        <div className="River_ChimeiPort_Container">
            <p>Fly Fishing in</p>
            <div>
                <div className="River_ChimeiPort_Line"></div>
                <h2>collon curra river</h2>
            </div>
        </div>
    )
}
