import React from "react";
import './River_XPort.css'


export default function River_XPort(){
    return(
        <div className="River_XPort_Container">
            <p>Fly Fishing in</p>
            <div>
                <div className="River_XPort_Line"></div>
                <h2>Malleo River</h2>
            </div>
        </div>
    )
}
