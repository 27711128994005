import React from "react";
import './River_4Description.css';


import LogoWifi from '../../../../assets/Icon/wifi.png';
import LogoMassage from '../../../../assets/Icon/massage.png';
import LogoFood from '../../../../assets/Icon/food.png';
import LogoCubiertos from '../../../../assets/Icon/cubiertos.png';


import LogoMore from '../../../../assets/Icon/more.png'
import LogoCaballo from '../../../../assets/Icon/cabalgata.png'
import LogoBinoculares from '../../../../assets/Icon/binoculares.png';
import LogoHiking from '../../../../assets/Icon/maps.png'

export default function River_4Description(){

    return(
        <div className="River_4Description_Container">

            <div className="River_4Description_SectionOne">
                <h2>Welcome to Tipiliuke</h2>
                <div className="River_4Description_Bars">/ / / / /</div>
                <p> Tipiliuke has just 9 guest rooms (all of which are en suite), allowing the team to focus on providing every guest with highly personalised service and exceptional attention to detail.</p>
                <p>Tipiliuke is flanked by the Chimehuin and Quilquihue rivers, known worldwide for their magnificent gin clear waters and exceptional trout fishing in Northern Patagonia.</p>
                <p>Tipiliuke is a mecca for both fauna and flora in the heart of Patagonia due to its unspoiled and varied habitats and plentiful water supplies. A working cattle ranch, over 4,000 head of cattle call Tipiliuke home on any given day, along with the 50 horses who work them. Just as they were over 100 years ago, cattle on Tipiliuke today are worked by local “gauchos” (Argentinian cowboys), many of whose families have been working the estancia for three generations.</p>

                <h3>Lodge amenites</h3>
                <div className="River_4Description_LineH"></div>
                <div className="River_4Description_ServicesIcon">
                    <div>
                        <img src={LogoWifi} alt="icon" />
                        <h4>Wifi</h4>
                    </div>
                    <div>
                        <img src={LogoMassage} alt="icon" />
                        <h4>Spa</h4>
                    </div>
                    <div>
                        <img src={LogoFood} alt="icon" />
                        <h4>cusine</h4>
                    </div>
                    <div>
                        <img src={LogoCubiertos} alt="Icons" />
                        <h4>Regional Food</h4>
                    </div>
                </div>
                <div></div>
            </div>

            {/* <div className="River_4Description_LineV"></div> */}

            <div className="River_4Description_SectionTwo">
                <h2>HIGHLIGHTS</h2>
                <div className="River_4Description_Bars">///</div>

                <div className="River_4Description_SectionTwo_Section">
                    <h3>location</h3>
                    <p>Located just minutes from the airport of San Martín De Los Andes (Chapelco), Tipiliuke Lodge is nestled at the heart of a 50,000 acre working cattle ranch.</p>
                </div>
                <div></div>

                <div className="River_4Description_SectionTwo_Section">
                    <h3>Other Activities</h3>
                    <div className="River_4Description_Programs">
                        <div>
                            <img src={LogoHiking} alt="" />
                            <p>hiking</p>
                        </div>
                        <div>
                            <img src={LogoCaballo} alt="" />
                            <p>Horseback Riding</p>
                        </div>
                        <div>
                            <img src={LogoBinoculares} alt="" />
                            <p>Bird Watching</p>
                        </div>
                        <div>
                            <img src={LogoMore} alt="" />
                            <p>More...</p>
                        </div>
                    </div>
                </div>

                {/* <div className="River_4Description_SectionTwo_Section">
                    <h3>Extra</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad ut, dolores ipsa porro quod possimus dicta nemo commodi labore nobis suscipit totam, alias error minima nisi accusamus rem? Quasi, cum. Molestiae nihil esse minus accusantium laborum magni voluptatibus saepe dicta, dolorem incidunt libero commodi vero perspiciatis quas rem? Laborum, iste?</p>
                </div> */}

            </div>
        </div>
    )
}