import React, { useEffect } from "react";
import Nav from "../../Nav/Nav";
import River_ChimeuinPort from "./River_ChimeuinPort/River_ChimeuinPort";
import RedirectContact from "../../Home/RedirectContact/RedirectContact";
import Footer from "../../Footer/Footer";
import Copyright from "../../Copirigth/Copyright";
import River_ChimeuinDescription from "./River_ChimeuinDescription/River_ChimeuinDescription";

import Img1 from '../../../assets/Rivers/chimeuin/chimeuin_1.jpeg'
import Img2 from '../../../assets/Rivers/chimeuin/chimeuin_3.jpg'
import Img3 from '../../../assets/Rivers/chimeuin/Chimeuin_8.jpg'
import Img4 from '../../../assets/Rivers/chimeuin/chimeuin_6.jpeg'
import Img5 from '../../../assets/Rivers/chimeuin/chimeuin_7.webp'
import Img6 from '../../../assets/Rivers/chimeuin/chimeuin_9.jpg'
import Photo from "../../Home/Photos/Photos";

export default function River_Chimeuin(){
    
    const arrImg = [Img1,Img2,Img3,Img4,Img5,Img6] 

    useEffect(()=>{
        window.scroll(0,0)
    },[])

    return(
        <div>
            <Nav/>

            <River_ChimeuinPort/>
            <RedirectContact/>
            <River_ChimeuinDescription/>
            <Photo arrImg={arrImg}/>

            <Footer/>
            <Copyright/>
        </div>
    )
}