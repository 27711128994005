import React, {useEffect} from "react";
import Nav from "../../Nav/Nav";
import River_LimaiPort from "./River_LimaiPort/River_LimaiPort";
import RedirectContact from "../../Home/RedirectContact/RedirectContact";
import Footer from "../../Footer/Footer";
import Copyright from "../../Copirigth/Copyright";
import River_LimaiDescription from "./River_LimaiDescription/River_LimaiDescription";

import Img1 from '../../../assets/Rivers/limai/limai_1.jpg'
import Img2 from '../../../assets/Rivers/limai/limai_2.jpeg'
import Img3 from '../../../assets/Rivers/limai/limai_3.jpeg'
import Img4 from '../../../assets/Rivers/limai/limai_4.jpg'
import Img5 from '../../../assets/Rivers/limai/limai_5.webp'
import Img6 from '../../../assets/Rivers/limai/limai_6.jpg'
import Photo from "../../Home/Photos/Photos";

export default function River_Limai(){

    useEffect(()=>{
        window.scroll(0,0)
    },[])
    
    const arrImg = [Img1,Img2,Img3,Img4,Img5,Img6] 

    return(
        <div>
            <Nav/>

            <River_LimaiPort/>
            <RedirectContact/>
            <River_LimaiDescription/>
            <Photo arrImg={arrImg}/>

            <Footer/>
            <Copyright/>
        </div>
    )
}