import React, {useEffect} from "react";
import './Lodges.css'
import Nav from "../../Nav/Nav";
import DescriptionLodge from "./DescriptionLodge/DescriptionLodge";
import RedirectContact from "../../Home/RedirectContact/RedirectContact";
import Photo from "../../Home/Photos/Photos";
import Footer from "../../Footer/Footer";
import Copyright from "../../Copirigth/Copyright";
import ListLodge from "./ListLodge/ListLodge";

export default function Lodges(){

    useEffect(()=>{
        window.scroll(0,0)
    },[])

    return(
        <div>
            <Nav/>
            <DescriptionLodge/>
            <RedirectContact/>
            {/* <Photo arrImg={arrImg} color={'black'}/> */}
            <ListLodge/>
            <Footer/>
            <Copyright/>
        </div>
    )
}