import React from "react";
import './Description.css'

export default function Description(){
    return(
        <div className="Description_Container">
            <div className="Description_TextContainer">
                <h2>Accommodation</h2>
                <p>
                In addition to our daily fishing excursions, we also offer the option to enjoy an unforgettable stay in our selected accommodations. You can relax in comfortable rooms and indulge in delicious meals prepared with the most authentic local flavors. We want our clients to feel like they are in a true Patagonian paradise, where rest and good company are part of the experience.

                At Patagonia Paradise, we take pride in working in harmony with nature and being responsible for the environment around us. We value the conservation of ecosystems and promote sustainable fishing practices to ensure that these natural wonders continue to be enjoyed by future generations.
                </p>
            </div>
            <div className="Description_ImgContainer">
            </div>
        </div>
    )
}