import React from "react";
import './River_ChimeiDescription.css';

export default function River_ChimeiDescription(){

    return(
        <div className="River_ChimeiDescription_Container">

            <div className="River_ChimeiDescription_SectionOne">
                <h2>Welcome to the collon cura River</h2>
                <div className="River_ChimeiDescription_Bars">/ / / / /</div>
                <p>The Collón Cura River is one of the most iconic rivers in Argentine Patagonia. It flows through the province of Neuquén, encompassing stunning and captivating landscapes. This river offers a unique and challenging fishing experience for sports fishing enthusiasts.<br/>

                The Collón Cura River is renowned for its abundant population of brown trout and rainbow trout. These species are highly valued for their size, strength, and beauty. Anglers who venture into the waters of the Collón Cura have the opportunity to hook trophy trout and enjoy an exhilarating battle while trying to master these magnificent fish.<br/>

                In addition to trout, the Collón Cura River is home to other fish species such as enclosed salmon and American perch. The diversity of species provides anglers with a wide range of options and challenges, making fishing in the Collón Cura River an exciting and rewarding experience.<br/>

                The landscape surrounding the Collón Cura River is truly breathtaking. Anglers immerse themselves in a wild and pristine natural environment, surrounded by majestic mountains, lush forests, and endless meadows. The scenic beauty of the Collón Cura River creates an atmosphere of serenity and connection with nature, making it an ideal place to escape the routine and enjoy an unforgettable fishing experience.</p>
                <p>At Patagonia Paradise, we offer specialized fishing guide services on the Collón Cura River. Our expert guides have a perfect knowledge of the best fishing spots and the most effective techniques to catch the species that inhabit these waters. Whether you prefer fly fishing, spinning, or baitcasting, our guides will tailor the experience to your preferences and skill level to ensure that you enjoy your fishing journey to the fullest.</p>

            </div>

            {/* <div className="River_ChimeiDescription_LineV"></div> */}

            <div className="River_ChimeiDescription_SectionTwo">
                <h2>HIGHLIGHTS Programs</h2>
                <div className="River_ChimeiDescription_Bars">///</div>

                <div className="River_ChimeiDescription_SectionTwo_Section">
                    <h3>location</h3>
                    <p>The Collón Curá River is a river located in the southwest of Argentina in the province of Neuquén. It originates at the confluence of the Aluminé and Chimehuin rivers and flows through a wide valley between plateaus, heading southeast, until it joins the Limay River, of which it is the main tributary.</p>
                </div>
                
                <div></div>

                <div className="River_ChimeiDescription_SectionTwo_Section">
                    <h3>OTHER INFORMATION</h3>
                    <p>The Collón Cura River, located in Neuquén, Argentina, is known for being a popular destination for fly fishing. The most common fish species in this river are brown trout, rainbow trout, and fontinalis trout. On average, brown and rainbow trout weigh between 1 to 2 kilograms, although larger specimens exceeding 3 kilograms can be found. It's important to note that these weights are general estimates and may vary depending on fishing conditions.</p>
                </div>

                <div></div>

                
            </div>
        </div>
    )
}