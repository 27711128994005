import React from "react";
import './River_LimaiDescription.css';

export default function River_LimaiDescription(){

    return(
        <div className="River_LimaiDescription_Container">

            <div className="River_LimaiDescription_SectionOne">
                <h2>Welcome to the Limai River</h2>
                <div className="River_LimaiDescription_Bars">/ / / / /</div>
                <p>The Limai River is located in the southern region of Argentina, specifically in the province of Neuquén. It originates in Lake Nahuel Huapi and flows south until it joins the Collón Curá River. The Limai River is known for its constant flow and crystal-clear waters, creating an ideal environment for sport fishing.<br/>

                In terms of fish species, the Limai River is home to an impressive variety. One of the most prominent species is salmon. The Limai River is known as a quality salmon fishing destination, with specimens that can reach considerable sizes and provide an exciting fight for anglers.<br/>

                In addition to salmon, the Limai River is also home to brown trout and rainbow trout. These trout species are appreciated for their beauty and their ability to offer a challenging fight. Anglers will find satisfaction in catching trout in the Limai River, showcasing their skills and adapting to different fishing techniques.</p>
                <p>The privileged location of the Limai River in Argentine Patagonia adds an exceptional scenic element to the fishing experience. Its mountainous landscapes, lush forests, and crystal-clear waters create an idyllic environment that immerses you in nature and allows you to disconnect from the outside world.</p>

            </div>

            {/* <div className="River_LimaiDescription_LineV"></div> */}

            <div className="River_LimaiDescription_SectionTwo">
                <h2>HIGHLIGHTS Programs</h2>
                <div className="River_LimaiDescription_Bars">///</div>

                <div className="River_LimaiDescription_SectionTwo_Section">
                    <h3>location</h3>
                    <p>The basin of the Limay River encompasses the southern sector of the Neuquén province and the northern sector of the Río Negro province.</p>
                </div>
                
                <div></div>

                <div className="River_LimaiDescription_SectionTwo_Section">
                    <h3>OTHER INFORMATION</h3>
                    <p>The Limay River is not only one of the most voluminous and important watercourses in the Patagonian region, but in its middle stretch, which extends for 70 kilometers, it has unique characteristics for fishing.<br/>

                    With a slope of one meter per kilometer and abundant flow, it allows for the capture of rainbow and brown trout that average between 700 grams and 2.5 kilograms. Additionally, there are also small-mouthed perch, Patagonian silverside, torrent catfish, and water matrincha, which are also valuable catches.</p>
                </div>

                <div></div>

                
            </div>
        </div>
    )
}