import React from "react";
import './River_2Port.css'


export default function River_2Port(){
    return(
        <div className="River_2Port_Container">
            <div>
                <h2>Tres Rios</h2>
                <div className="River_2Port_Line"></div>
            </div>
            <p> Private access to ten miles of the Lower Chimehuin River.</p>
        </div>
    )
}