import React from "react";
import Nav from "../Nav/Nav";
import Copyright from "../Copirigth/Copyright";
import Footer from "../Footer/Footer";
import './Error404.css'

export default function Error404(){

    setTimeout(function() {
        window.location.href = "/";
    }, 1500);


    return (
        <div>
            <Nav/>
            <div className="error_container">
                <h2>ERROR 404!</h2>
                <p>Page not found, you will be redirected shortly.</p>
            </div>
            <Footer/>
            <Copyright/>
        </div>
    )
}