import React from "react";
import './River_ChimeuinPort.css'


export default function River_ChimeuinPort(){
    return(
        <div className="River_ChimeuinPort_Container">
            <p>Fly Fishing in</p>
            <div>
                <div className="River_ChimeuinPort_Line"></div>
                <h2>Chimeuin River</h2>
            </div>
        </div>
    )
}
