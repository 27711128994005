import React, {useEffect} from "react";
import './Gallery.css';

import Nav from '../Nav/Nav';
import Footer from '../Footer/Footer';
import Copirigth from "../Copirigth/Copyright"
import Reel from "./Reel/Reel";
import RedirectContact from "../Home/RedirectContact/RedirectContact";

import Img_01 from '../../assets/Fishing/Pic_01.jpeg'
import Img_02 from '../../assets/Fishing/Pic_02.jpeg'
import Img_03 from '../../assets/Fishing/Pic_03.jpeg'
import Img_04 from '../../assets/Fishing/Pic_04.jpeg'
import Img_05 from '../../assets/Fishing/Pic_05.jpeg'
import Img_06 from '../../assets/Fishing/Pic_06.jpeg'
import Img_07 from '../../assets/Fishing/Pic_07.jpeg'
import Img_08 from '../../assets/Fishing/Pic_08.jpeg'
import Img_09 from '../../assets/Fishing/Pic_09.jpeg'
import Img_10 from '../../assets/Fishing/Pic_10.jpeg'
import Img_11 from '../../assets/Fishing/Pic_11.jpeg'
import Img_12 from '../../assets/Fishing/Pic_12.jpeg'
import Img_13 from '../../assets/Fishing/Pic_13.jpeg'
import Img_14 from '../../assets/Fishing/Pic_14.jpeg'
import Img_15 from '../../assets/Fishing/Pic_15.jpeg'
import Img_16 from '../../assets/Fishing/Pic_16.jpeg'
import Img_17 from '../../assets/Fishing/Pic_17.jpeg'
import Img_18 from '../../assets/Fishing/Pic_18.jpeg'
import Img_19 from '../../assets/Fishing/Pic_19.jpeg'
import Img_20 from '../../assets/Fishing/Pic_20.jpeg'
import Img_21 from '../../assets/Fishing/Pic_21.jpeg'
import Img_22 from '../../assets/Fishing/Pic_22.jpeg'
import Img_23 from '../../assets/Fishing/Pic_23.jpeg'
import Img_24 from '../../assets/Fishing/Pic_24.jpeg'
import Img_25 from '../../assets/Fishing//Pic_25.jpeg'


export default function Gallery(){

    const arrImg = [Img_01,Img_02,Img_03,Img_04,Img_05,Img_06,Img_07,Img_08,Img_09,Img_10,Img_11,Img_12,Img_13,Img_14,Img_15,Img_16,Img_17,Img_18,Img_19,Img_20,Img_21,Img_22,Img_23,Img_24,Img_25]

    useEffect(()=>{
        window.scroll(0,0)
    },[])

    return(
        <div className="Gallery_Container">
            <Nav/>
            <div className="Gallery_Div"></div>
            <Reel arrImg={arrImg}/>
            <RedirectContact color='Black'/>
            <Footer/>
            <Copirigth/>
        </div>
    )
}