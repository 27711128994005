import React , { useEffect}from "react";
import Nav from "../../Nav/Nav";
import River_ChimeiPort from "./River_ChimeiPort/River_ChimeiPort";
import RedirectContact from "../../Home/RedirectContact/RedirectContact";
import Footer from "../../Footer/Footer";
import Copyright from "../../Copirigth/Copyright";
import River_ChimeiDescription from "./River_ChimeiDescription/River_ChimeiDescription";

import Img1 from '../../../assets/Rivers/collonrCura/colloncura_1.webp'
import Img2 from '../../../assets/Rivers/collonrCura/colloncura_2.webp'
import Img3 from '../../../assets/Rivers/collonrCura/colloncura_3.webp'
import Img4 from '../../../assets/Rivers/collonrCura/colloncura_4.jpg'
import Img5 from '../../../assets/Rivers/collonrCura/colloncura_5.webp'
import Img6 from '../../../assets/Rivers/collonrCura/colloncura_7.webp'
import Photo from "../../Home/Photos/Photos";

export default function River_Chimei(){

    
    const arrImg = [Img1,Img2,Img3,Img4,Img5,Img6] 

    useEffect(()=>{
        window.scroll(0,0)
    },[])

    return(
        <div>
            <Nav/>

            <River_ChimeiPort/>
            <RedirectContact/>
            <River_ChimeiDescription/>
            <Photo arrImg={arrImg}/>

            <Footer/>
            <Copyright/>
        </div>
    )
}