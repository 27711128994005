import React from "react";
import './Welcome.css';

export default function Welcome(){
    return(
        <div className="Welcome_Container">
            <div className="Welcome_ImgContainer">
            </div>
            <div className="Welcome_TextContainer">
                <h2>Fly Fishing Experience</h2>
                <p>
                Patagonia Paradise is excited to share with you some of the best rivers for fly fishing in the area. From our base in San Martin de los Andes, we offer you access to more than six different rivers and the privilege to stay in some of the finest estancias with miles of private access to the best waters and wild trout.<br/>

                Professionalism and flexibility are what we offer when planning these unique fishing experiences.<br/>

                Maximize your fishing experience with the best access to the river while enjoying different views of Patagonia's landscape, Argentinean culture, food, and wines.<br/>

                You will be fishing with professional guides who are fluent in English.<br/>

                We look forward to sharing all of our experience with you<br/>
                </p>
            </div>
        </div>
    )
}