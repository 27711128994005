import React, {useEffect} from "react";
import Nav from "../../Nav/Nav";
import River_2Port from "./River_2Port/River_2Port";
import RedirectContact from "../../Home/RedirectContact/RedirectContact";
import Footer from "../../Footer/Footer";
import Copyright from "../../Copirigth/Copyright";
import River_2Description from "./River_2Description/River_2Description";

import Img1 from '../../../assets/lodges/tresRios/living.jpg'
import Img2 from '../../../assets/lodges/tresRios/living_2.jpg'
import Img3 from '../../../assets/lodges/tresRios/room.jpg'
import Img4 from '../../../assets/lodges/tresRios/room_2.jpg'
import Img5 from '../../../assets/lodges/tresRios/living_3.jpg'
import Img6 from '../../../assets/lodges/tresRios/cup.jpg'
import Img7 from '../../../assets/lodges/tresRios/outside_3.jpg'
import Img8 from '../../../assets/lodges/tresRios//outside_2.jpg'
import Img9 from '../../../assets/lodges/tresRios/outside.jpg'
import Photo from "../../Home/Photos/Photos";

export default function River_2(){

    
    const arrImg = [Img1,Img2,Img3,Img4,Img5,Img6,Img7,Img8,Img9] 

    useEffect(()=>{
        window.scroll(0,0)
    },[])

    return(
        <div>
            <Nav/>

            <River_2Port/>
            <RedirectContact/>
            <River_2Description/>
            <Photo arrImg={arrImg}/>

            <Footer/>
            <Copyright/>
        </div>
    )
}