import React, { useState } from "react";
import './Reel.css';

export default function Reel({arrImg}){

    const [position, setPosition] = useState(0)

    function NextImg(e, state){
        if(e ==1 && state < arrImg.length-1){
            return setPosition(state+1)
        }
        if(e == 0 && state > 0){
            return setPosition(state-1)
        }
    }

    return(
        <div className="Reel_Container">
            <div className="Reel_Reel">
                <button className={position == 0? "Reel_Button Reel_ButtonBlack" : "Reel_Button"} onClick={()=>NextImg(0,position)}><i class='bx bx-chevron-left'></i></button>
                <div className="Reel_ImgContainer">
                    <img src={arrImg? arrImg[position]: ''} alt="" />
                </div>
                <button className={position == arrImg.length-1? "Reel_Button Reel_ButtonBlack" : "Reel_Button"} onClick={()=>NextImg(1,position)}><i class='bx bx-chevron-right' ></i></button>
            </div>
            <div className="Reel_Index">
                <p className={position-1 == 0 || position == 0? 'Reel_None' : ''}>+{position-1}</p>
                <p className={position == 0 ? 'Reel_None' : ''}>{position}</p>
                <p>{position+1}</p>
                <p className={position == arrImg.length-1? 'Reel_None' : ''}>{position+2}</p>
                <p className={position == arrImg.length-1 || position+1 == arrImg.length-1 ? 'Reel_None' : ''}>+{arrImg.length-(position+1)}</p>
            </div>
        </div>
    )
}