import React from "react";
import './Slaider.css'
import { Link } from "react-router-dom";

export default function Slaider(){
    return(
        <div className="Slaider_Container">
            <div className="Slaider_Filter">
                <div className="Slaider_TextContent">
                    <p>Live a unique experience with</p>
                    <h1>Patagonia paradise</h1>
                </div>
                <div className="Slaider_SlashSeparator"></div>
                <div className="Slaider_ButtonContainer">
                    <Link className="Slaider_Button" to={"/Lodges/"}>Lodges</Link>
                    <Link className="Slaider_Button" to="/Contact">Contact</Link>
                </div>
            </div>
        </div>
    )
}