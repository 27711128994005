import React, {useEffect} from "react";
import Nav from "../../Nav/Nav";
import River_5Port from "./River_5Port/River_5Port";
import RedirectContact from "../../Home/RedirectContact/RedirectContact";
import Footer from "../../Footer/Footer";
import Copyright from "../../Copirigth/Copyright";
import River_5Description from "./River_5Description/River_5Description";

import Img1 from '../../../assets/lodges/riverhouse/living.jpg'
import Img2 from '../../../assets/lodges/riverhouse/living_1.jpg'
import Img3 from '../../../assets/lodges/riverhouse/living_3.jpg'
import Img4 from '../../../assets/lodges/riverhouse/outside.jpg'
import Img5 from '../../../assets/lodges/riverhouse/outside_2.jpg'
import Img6 from '../../../assets/lodges/riverhouse/room.jpg'
import Img7 from '../../../assets/lodges/riverhouse/living_4.jpg'
import Img8 from '../../../assets/lodges/riverhouse/games.jpg'
import Photo from "../../Home/Photos/Photos";

export default function River_5(){

    
    const arrImg = [Img1,Img2,Img3,Img4,Img5,Img6,Img7,Img8] 

    useEffect(()=>{
        window.scroll(0,0)
    },[])

    return(
        <div>
            <Nav/>

            <River_5Port/>
            <RedirectContact/>
            <River_5Description/>
            <Photo arrImg={arrImg}/>

            <Footer/>
            <Copyright/>
        </div>
    )
}