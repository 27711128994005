import React, { useEffect } from "react";
import Nav from "../Nav/Nav";
import './Home.css'
import Slaider from "../Slaider/Slaider";
import RedirectContact from "./RedirectContact/RedirectContact";
import Copirigth from "../Copirigth/Copyright";
import Welcome from "./Welcome/Welcome";
import Description from "./Description/Description";
import Photo from "./Photos/Photos";
import ModalPhoto from "./Photos/ModalPhoto/ModalPhoto";
import Footer from "../Footer/Footer";
import Img1 from '../../assets/pic_gallery/IMG-20230524-WA0067.jpg'
import Img2 from '../../assets/pic_gallery/IMG-20230524-WA0065.jpg'
import Img3 from '../../assets/pic_gallery/IMG-20230524-WA0063.jpg'
import Img4 from '../../assets/Fishing/Pic_05.jpeg'
import Img5 from '../../assets/Fishing/Pic_22.jpeg'
import Img6 from '../../assets/Fishing/Pic_12.jpeg'

export default function Home(){

    const arrImg = [Img1, Img2, Img3, Img4, Img5, Img6] 

    useEffect(()=>{
        window.scroll(0,0)
    },[])

    return(
        <div className="Home_Container">
            <Nav/>

            <Slaider/>

            <RedirectContact/>

            <Welcome/>
            <Photo arrImg={arrImg}/>
            <Description/>

            <Footer/>

            <Copirigth/>
        </div>
    )

}