import React, {useEffect} from "react";
import Nav from "../../Nav/Nav";
import River_XPort from "./River_XPort/River_XPort";
import RedirectContact from "../../Home/RedirectContact/RedirectContact";
import Footer from "../../Footer/Footer";
import Copyright from "../../Copirigth/Copyright";
import River_XDescription from "./River_XDescription/River_XDescription";

import Img1 from '../../../assets/Rivers/malleo/malleo_6.jpg'
import Img2 from '../../../assets/Rivers/malleo/malleo_2.jpg'
import Img3 from '../../../assets/Rivers/malleo/malleo_3.webp'
import Img4 from '../../../assets//Rivers/malleo/malleo_4.jpg'
import Img5 from '../../../assets/Rivers/malleo/malleo_5.webp'
import Img6 from '../../../assets/Rivers/malleo/malleo_7.webp'
import Photo from "../../Home/Photos/Photos";

export default function River_X(){

    
    const arrImg = [Img1,Img2,Img3,Img4,Img5,Img6] 

    useEffect(()=>{
        window.scroll(0,0)
    },[])

    return(
        <div>
            <Nav/>

            <River_XPort/>
            <RedirectContact/>
            <River_XDescription/>
            <Photo arrImg={arrImg}/>

            <Footer/>
            <Copyright/>
        </div>
    )
}