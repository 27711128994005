import React from "react";
import './River_3Description.css';

import LogoWifi from '../../../../assets/Icon/wifi.png'
import LogoPrivate from '../../../../assets/Icon/private.png'
import LogoFood from '../../../../assets/Icon/food.png'

import LogoBinoculares from '../../../../assets/Icon/binoculares.png'

export default function River_2Description(){

    return(
        <div className="River_3Description_Container">

            <div className="River_3Description_SectionOne">
                <h2>Welcome to COLLON CURA</h2>
                <div className="River_3Description_Bars">/ / / / /</div>
                <p>ESTANCIA COLLON CURA IS LOCATED ABOUT AN HOUR FROM CHAPELCO AIRPORT. THIS LODGE OFFERS PRIVATE ACCESS TO THE BEST SECTION OF THE LOWER COLLON CURA RIVER. IT IS VERY FUNCTIONAL AND PLEASANT - SIMPLICITY MAKES IT WORK PERFECTLY. THE LODGE OFFERS 4 COMFORTABLE DOUBLE ROOMS WITH PRIVATE BATHROOMS, SHARING A SMALL LIVING ROOM, WITH CAPACITY FOR 8 GUESTS MAXIMUM. THE SERVICE AND MEALS ARE INCREDIBLY GOOD.</p>
                <p>With just 4 double rooms with private bathrooms, guests can enjoy a full house service focused on every detail anglers and non-anglers need.<br/>

                At the Collon Cura Lodge, we strive to make every meal an extraordinary experience, not just in the lodge but with your shore side river lunches too.</p>

                <h3>SERVICES</h3>
                <div className="River_3Description_LineH"></div>
                <div className="River_3Description_ServicesIcon">
                    <div>
                        <img src={LogoWifi} alt="" />
                        <h4>Wifi</h4>
                    </div>
                    <div>
                        <img src={LogoFood} alt="" />
                        <h4>Regional Food</h4>
                    </div>
                    <div>
                        <img src={LogoPrivate} alt="" />
                        <h4>Private</h4>
                    </div>
                </div>
                <div></div>
            </div>

            {/* <div className="River_1Description_LineV"></div> */}

            <div className="River_3Description_SectionTwo">
                <h2>HIGHLIGHTS</h2>
                <div className="River_3Description_Bars">///</div>

                <div className="River_3Description_SectionTwo_Section">
                    <h3>location</h3>
                    <p>LESTANCIA COLLON CURA IS LOCATED ABOUT AN HOUR FROM CHAPELCO AIRPORT</p>
                </div>

                <div></div>

                {/* <div className="River_3Description_SectionTwo_Section">
                    <h3>Other Activities</h3>
                    <div className="River_3Description_Programs">
                        <div>
                            <img src={LogoBinoculares} alt="" />
                            <p>Fisign Nature</p>
                        </div>
                        <div>
                            <img src={LogoBinoculares} alt="" />
                            <p>Fisign Nature</p>
                        </div>
                        <div>
                            <img src={LogoBinoculares} alt="" />
                            <p>Fisign Nature</p>
                        </div>
                        <div>
                            <img src={LogoBinoculares} alt="" />
                            <p>Fisign Nature</p>
                        </div>
                    </div>
                </div> */}

                {/* <div className="River_3Description_SectionTwo_Section">
                    <h3>Extra</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad ut, dolores ipsa porro quod possimus dicta nemo commodi labore nobis suscipit totam, alias error minima nisi accusamus rem? Quasi, cum. Molestiae nihil esse minus accusantium laborum magni voluptatibus saepe dicta, dolorem incidunt libero commodi vero perspiciatis quas rem? Laborum, iste?</p>
                </div> */}

            </div>
        </div>
    )
}