import React from "react";
import './River_5Port.css'


export default function River_5Port(){
    return(
        <div className="River_5Port_Container">
            <div>
                <h2>river house</h2>
                <div className="River_5Port_Line"></div>
            </div>
            <p>Experience the rivers of Patagonia like never before.</p>
        </div>
    )
}