import React from "react";
import './Footer.css'
import Logo from '../../assets/icon.svg'

export default function Footer(){
    return(
        <div className="Footer_Container">
            <div className="Footer_ImgContainer">
                <img src={Logo} alt="Logo" />
            </div>
            <div className="Footer_TexContainer">
                <div>
                    <h2>Contact</h2>
                    <p>Phone Number 1: +54 9 2972-470430</p>
                    <p>Phone Number 2: +54 9 2944-665825</p>
                    <p>Email: <a href="mailto:ad.cataldi@gmail.com" className="Footer_Mail">ad.cataldi@gmail.com</a></p>
                    <p>Ubication: San Martin De los Andes</p>
                    <div className="Footer_Social">
                        <a href="https://www.instagram.com/" target="_blank"><i class='bx bxl-instagram Copyright_Icon'></i></a>
                        <a href="https://wa.me/+5492972470430" target="_blank"><i class='bx bxl-whatsapp Copyright_Icon' ></i></a>
                        <a href="https://www.facebook.com/" target="_blank"><i class='bx bxl-facebook-circle Copyright_Icon' ></i></a>
                    </div>
                </div>
            </div>
        </div>
    )
}