import React from "react";
import './River_XDescription.css';

export default function River_XDescription(){

    return(
        <div className="River_XDescription_Container">

            <div className="River_XDescription_SectionOne">
                <h2>Welcome to the malleo River</h2>
                <div className="River_XDescription_Bars">/ / / / /</div>
                <p>Discover the beauty and excitement of fishing in the Malleo River, a true paradise in Patagonia. At Patagonia Paradise, we invite you to immerse yourself in a world of stunning landscapes and unforgettable experiences.<br/>

                Imagine being surrounded by majestic mountains, lush forests, and crystal-clear waters that reflect the blue sky. The Malleo River, with its vigorous flow and serene current, will envelop you in a unique and captivating natural environment. Every cast of the fishing rod will transport you to a state of deep connection with nature, where stress fades away and only the excitement of fishing remains.<br/>

                Our Malleo River is known for hosting an abundant population of impressive-sized brown and rainbow trout. Each catch represents an exciting challenge and a reward in the form of a vigorous fight with these magnificent fish.<br/>

                At Patagonia Paradise, we take pride in offering an exceptional fishing experience. Our expert guides will accompany you every step of the way, sharing their knowledge and techniques to help you achieve unforgettable catches. Additionally, our carefully selected fishing areas ensure sustainable and environmentally respectful fishing practices.</p>
                <p>Not only will you enjoy world-class fishing, but you will also be amazed by the natural beauty that surrounds the Malleo River. Explore scenic trails, observe native birds in their natural habitat, and immerse yourself in the tranquility of Patagonia. Our goal is to provide you with a comprehensive experience where fishing seamlessly blends with the contemplation of stunning landscapes.</p>

            </div>

            {/* <div className="River_XDescription_LineV"></div> */}

            <div className="River_XDescription_SectionTwo">
                <h2>HIGHLIGHTS Programs</h2>
                <div className="River_XDescription_Bars">///</div>

                <div className="River_XDescription_SectionTwo_Section">
                    <h3>location</h3>
                    <p>The Malleo River originates in Lake Tromen in the Neuquén Andes mountain range, within the Lanín National Park, until it flows into the Aluminé River.</p>
                </div>
                
                <div></div>

                <div className="River_XDescription_SectionTwo_Section">
                    <h3>OTHER INFORMATION</h3>
                    <p>The Malleo River is home to considerable-sized brown and rainbow trout. The fishing season runs from November to April or May. The captured trout have an average weight of 1 to 2 kilograms, but larger specimens can also be found. The Malleo River offers anglers an exciting fishing experience in a stunning natural environment.</p>
                </div>

                <div></div>

                
            </div>
        </div>
    )
}