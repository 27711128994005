import React from "react";
import { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './Form.css';
import Img1 from '../../../assets/pic_gallery/IMG-20230524-WA0067.jpg'
import Img2 from '../../../assets/pic_gallery/IMG-20230524-WA0065.jpg'
import Img3 from '../../../assets/pic_gallery/IMG-20230524-WA0069.jpg'
import Img4 from '../../../assets/pic_gallery/IMG-20230524-WA0066.jpg'

export default function Form(){

    const form = useRef();
    const [modal, setModal] = useState(0);
    const [erorr ,setError] = useState(false)


    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_4ox46zk', 'template_3uicuue', form.current, 'SOoCx03YnC4bE2f_s')
          e.target.reset()
          setModal(1)
    };

    return(
        <div className="Form_Container">
            <form ref={form} onSubmit={sendEmail} className="Form_Form">
                <div className="From_ImgMobile">
                    <img src={Img1} alt="Lodge patagonia paradice" />
                    <img src={Img4} alt="Lodge patagonia paradice" />
                    <img src={Img3} alt="Lodge patagonia paradice" />
                    <img src={Img2} alt="Lodge patagonia paradice" />
                </div>
                <div className="Form_Text Form_DivContent">
                    <h2>Contact Us</h2>
                    <div>/ / / /</div>
                    <p>
                    We are based in San Martin de los Andes, which is located in a beautiful valley on the shore of Lacar Lake.<br/>

                    We have been offering fly fishing and guiding services in Patagonian rivers and lakes for the last 30 years. We invite you to join us in experiencing some of the finest dry fly fishing in the world for wild rainbows and browns.<br/>
                    Send us a message and we will get back to you shortly. Thank you!
                    </p>
                </div>
                <div className=" Form_DivContent">
                    <label>Name:</label>
                    <input type="text"  name="name" placeholder="Name..." required autocomplete="on"/>
                </div>
                <div className=" Form_DivContent">
                    <label>Email:</label>
                    <input type="email" name="email" placeholder="Email..." required autocomplete="on"/>
                </div>
                <div className=" Form_DivContent">
                    <label>Message:</label>
                    <textarea type="text" name="project" placeholder="Message..." required/>
                </div>
                <button  type="submit" >Submit</button>
            </form>
            <div className="Form_ImgContainer">
                {/* <img src={Img1} alt="" /> */}
            </div>
        </div>
    )
}

    