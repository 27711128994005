import React from "react";
import { Link } from "react-router-dom";
import './RedirectContact.css'

export default function RedirectContact({color}){
    return(
        <div className={`RedirectContact_Container RedirectContact_${color}`}>
            <div className="RedirectContact_TextContainer">
                <p>
                    FISH THE BEST RIVERS, AT THE BEST TIMES, WITH THE BEST GUIDES
                </p>
                <h2>Patagonia paradise</h2>
            </div>
            <div className="RedirectContact_Buttoncontainer">
                <Link className="RedirectContact_Button" to="/Contact">Contact US</Link>
            </div>
        </div>
    )
}