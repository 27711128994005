import React from "react";
import './River_2Description.css';

import LogoWifi from '../../../../assets/Icon/wifi.png';
import LogoFood from '../../../../assets/Icon/food.png';
import LogoCubiertos from '../../../../assets/Icon/cubiertos.png';
import LogoPrivate from '../../../../assets/Icon/private.png';
import LogoBinoculares from '../../../../assets/Icon/binoculares.png';
import LogoMore from '../../../../assets/Icon/more.png';
import LogoMaps from '../../../../assets/Icon/maps.png';



export default function River_2Description(){

    return(
        <div className="River_2Description_Container">

            <div className="River_2Description_SectionOne">
                <h2>Welcome to Tres Rios</h2>
                <div className="River_2Description_Bars">/ / / / /</div>
                <p>TRES RIOS LODGE IS LOCATED ONE HOUR FROM CHAPELCO AIRPORT. THIS LODGE HOLDS UP TO EIGHT GUESTS IN FOUR DOUBLE OCCUPANCY ROOMS, PROVIDING A MORE INTIMATE AND EXCLUSIVE EXPERIENCE THAN OTHER ESTANCIAS. THE LODGE IS BEAUTIFULLY CONSTRUCTED WITH A SPACIOUS LIVING ROOM AND BAR AREA CONNECTED TO AN ADJACENT DINING ROOM. THE LODGE HAS AN ADDITIONAL BUILDING WHICH INCLUDES THE MUD ROOM AND WELCOMING FIRE-PIT FOR ASADOS, AND TO ENJOY A REFRESHING COCKTAIL BEFORE EACH DINNER. AN EXPERIENCED CHEF PREPARES GOURMET AND LOCAL MEALS WHICH ARE ALWAYS ACCOMPANIED BY A GREAT SELECTION OF ARGENTINIAN WINES. TRES RIOS LODGE HAS PRIVATE ACCESS TO TEN MILES OF THE CHIMEHUIN RIVER - AN ACCESSIBLE RIVER TO WADE, AND A DYNAMIC RIVER TO FLOAT FOR THOSE WHO WOULD LIKE TO COVER MORE WATER.</p>
                <p>Although remote and isolated, the lodge is just under an hour away from San Martin de los Andes, allowing those looking to do more than fish to escape to town and enjoy an array of activities. Tres Rios Lodge is ideal for guests and anglers looking to enjoy the peace and tranquility of a smaller lodge set in a pristine and quiet environment.</p>

                <h3>SERVICES</h3>
                <div className="River_2Description_LineH"></div>
                <div className="River_2Description_ServicesIcon">
                    <div>
                        <img src={LogoWifi} alt="Icons" />
                        <h4>Wifi</h4>
                    </div>
                    <div>
                        <img src={LogoFood} alt="Icons" />
                        <h4>Gourmet Food</h4>
                    </div>
                    <div>
                        <img src={LogoCubiertos} alt="Icons" />
                        <h4>Regional Food</h4>
                    </div>
                    <div>
                        <img src={LogoPrivate} alt="Icons" />
                        <h4>Private</h4>
                    </div>
                </div>
                <div></div>
            </div>

            {/* <div className="River_1Description_LineV"></div> */}

            <div className="River_2Description_SectionTwo">
                <h2>HIGHLIGHTS</h2>
                <div className="River_2Description_Bars">///</div>

                <div className="River_2Description_SectionTwo_Section">
                    <h3>location</h3>
                    <p>Tres Rios Lodge is a more remote lodge that is roughly 45 minutes from the San Martin airport. It sits on the confluences of the Chimehuin and Collon Cura rivers, making it an ideal spot to take advantage of close proximity fishing.</p>
                </div>

                <div></div>

                <div className="River_2Description_SectionTwo_Section">
                    <h3>Other Activities</h3>
                    <div className="River_2Description_Programs">
                        <div>
                            <img src={LogoMaps} alt="Icon" />
                            <p>Hiking</p>
                        </div>
                        <div>
                            <img src={LogoBinoculares} alt="Icon" />
                            <p>Bird Watching</p>
                        </div>
                        <div>
                            <img src={LogoMore} alt="Icon" />
                            <p>More...</p>
                        </div>
                    </div>
                </div>

                {/* <div className="River_2Description_SectionTwo_Section">
                    <h3>Extra</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad ut, dolores ipsa porro quod possimus dicta nemo commodi labore nobis suscipit totam, alias error minima nisi accusamus rem? Quasi, cum. Molestiae nihil esse minus accusantium laborum magni voluptatibus saepe dicta, dolorem incidunt libero commodi vero perspiciatis quas rem? Laborum, iste?</p>
                </div> */}

            </div>
        </div>
    )
}