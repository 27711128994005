import React from "react";
import './River_5Description.css';


import LogoWifi from '../../../../assets/Icon/wifi.png';
import LogoMassage from '../../../../assets/Icon/massage.png';
import LogoFood from '../../../../assets/Icon/food.png';
import LogoCubiertos from '../../../../assets/Icon/cubiertos.png';


import LogoMore from '../../../../assets/Icon/more.png'
import LogoCaballo from '../../../../assets/Icon/cabalgata.png'
import LogoBinoculares from '../../../../assets/Icon/binoculares.png';
import LogoHiking from '../../../../assets/Icon/maps.png'

export default function River_5Description(){

    return(
        <div className="River_5Description_Container">

            <div className="River_5Description_SectionOne">
                <h2>Welcome to River House</h2>
                <div className="River_5Description_Bars">/ / / / /</div>
                <p> River House Lodge is located on the Chimehuin River in the north of the Argentine Patagonia. The airport is 20 minutes away from the lodge and the closest cities are Junín de los Andes and San Martin de los Andes- in Neuquén province -20 and 40 minutes away respectively.</p>
                <p>The mythical Chimehuin is one of the most famous rivers in the world for fly fishing. Wading or floating “el Chime” is always an unforgettable experience due to the quality of its trout, its landscape and its gin clear waters.</p>
                <p>The lodge has five spacious en-suite rooms with excellent amenities and views. This place allows us to host up to 10 people at the same time, which is an ideal number to be able to maintain a warm and intimate atmosphere that favours quality care. We pay special attention to details because we love what we do and we want customers to feel happy, cared for an feel at home.</p>

                <h3>Lodge amenites</h3>
                <div className="River_5Description_LineH"></div>
                <div className="River_5Description_ServicesIcon">
                    <div>
                        <img src={LogoWifi} alt="icon" />
                        <h4>Wifi</h4>
                    </div>
                    <div>
                        <img src={LogoMassage} alt="icon" />
                        <h4>Spa</h4>
                    </div>
                    <div>
                        <img src={LogoFood} alt="icon" />
                        <h4>cusine</h4>
                    </div>
                    <div>
                        <img src={LogoCubiertos} alt="Icons" />
                        <h4>Regional Food</h4>
                    </div>
                </div>
                <div></div>
            </div>

            {/* <div className="River_5Description_LineV"></div> */}

            <div className="River_5Description_SectionTwo">
                <h2>HIGHLIGHTS</h2>
                <div className="River_5Description_Bars">///</div>

                <div className="River_5Description_SectionTwo_Section">
                    <h3>location</h3>
                    <p>It is located in the lower valley of the Chimehuín River, 20 minutes by car from the Chapelco airport, between two towns, 22 kilometers from the town of Junín de los Andes and 35 kilometers from the town of San Martín de los Andes, in the Patagonian region.</p>
                </div>
                <div></div>

                <div className="River_5Description_SectionTwo_Section">
                    <h3>Other Activities</h3>
                    <div className="River_5Description_Programs">
                        <div>
                            <img src={LogoHiking} alt="" />
                            <p>hiking</p>
                        </div>
                        <div>
                            <img src={LogoCaballo} alt="" />
                            <p>Horseback Riding</p>
                        </div>
                        <div>
                            <img src={LogoBinoculares} alt="" />
                            <p>Bird Watching</p>
                        </div>
                        <div>
                            <img src={LogoMore} alt="" />
                            <p>More...</p>
                        </div>
                    </div>
                </div>

                {/* <div className="River_5Description_SectionTwo_Section">
                    <h3>Extra</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad ut, dolores ipsa porro quod possimus dicta nemo commodi labore nobis suscipit totam, alias error minima nisi accusamus rem? Quasi, cum. Molestiae nihil esse minus accusantium laborum magni voluptatibus saepe dicta, dolorem incidunt libero commodi vero perspiciatis quas rem? Laborum, iste?</p>
                </div> */}

            </div>
        </div>
    )
}