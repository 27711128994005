import React from "react";
import './River_1Port.css'


export default function River_1Port(){
    return(
        <div className="River_1Port_Container">
            <div>
                <h2>San Hubberto</h2>
                <div className="River_1Port_Line"></div>
            </div>
            <p>Fish the incredible Malleo River.</p>
        </div>
    )
}