import React from "react";
import './River_1Description.css';

import LogoWifi from '../../../../assets/Icon/wifi.png';
import LogoJacuzzi from '../../../../assets/Icon/hottub.png';
import LogoMassage from '../../../../assets/Icon/massage.png';
import LogoLavanding from '../../../../assets/Icon/lavanding.png';
import LogoTreking from '../../../../assets/Icon/trekking.png';
import LogoBinoculares from '../../../../assets/Icon/binoculares.png';
import LogoCabalgata from '../../../../assets/Icon/cabalgata.png';
import LogoMore from '../../../../assets/Icon/more.png'

export default function River_1Description(){

    return(
        <div className="River_1Description_Container">

            <div className="River_1Description_SectionOne">
                <h2>Welcome to San Hubberto</h2>
                <div className="River_1Description_Bars">/ / / / /</div>
                <p>SAN HUBERTO LODGE IS THE BEST OPTION TO FISH THE INCREDIBLE MALLEO RIVER. THIS AMAZING LODGE HAS ROOM FOR UP TO SIXTEEN GUESTS, WITH A MAXIMUM OF TWELVE ANGLERS. THE LODGE IS MANAGED BY THE OLSEN FAMILY, WITH MORE THAN 20 YEARS TAKING CARE OF GUESTS WHERE EVERY DETAIL HAS BEEN STUDIED TO MAKE EVERYTHING JUST PERFECT. THE LODGE INCLUDES AN INHOUSE BAR, HOT TUB, MASSEUSE AND A “QUINCHO” FOR THE SUNDAY “ASADO”. GUESTS HAVE ACCESS TO OVER 20 MILES OF SPECTACULAR WADE FISHING AND PRIVATE ACCESS TO THE HUACA MAMUIL SPRING CREEK, FAMOUS FOR ITS DRY FLY FISHING.

                </p>
                <p>The Lodge provides an all inclusive style service with excellent food and personalized service. Offers 10 spacious rooms (can accommodate 16 guests in four single and six double rooms) with private bathroom, living room with library and fire place, dinning room, after fishing open bar with regional snacks and drinks, hot tub, massage room, phone-fax-internet service (wi-fi), and more....</p>

                <h3>Services</h3>
                <div className="River_1Description_LineH"></div>
                <div className="River_1Description_ServicesIcon">
                    <div>
                        <img src={LogoWifi} alt=""/>
                        <h4>Wifi</h4>
                    </div>
                    <div>
                        <img src={LogoJacuzzi} alt=""/>
                        <h4>Jacuzzi</h4>
                    </div>
                    <div>
                        <img src={LogoMassage} alt="" />
                        <h4>Massage</h4>
                    </div>
                    <div>
                        <img src={LogoLavanding} alt="" />
                        <h4>Laundry</h4>
                    </div>
                </div>
                <div></div>
            </div>

            {/* <div className="River_1Description_LineV"></div> */}

            <div className="River_1Description_SectionTwo">
                <h2>HIGHLIGHTS</h2>
                <div className="River_1Description_Bars">///</div>

                <div className="River_1Description_SectionTwo_Section">
                    <h3>Location</h3>
                    <p>45 minutes away from Chapelco airport accessible through paved road.</p>
                    <p>1 hour from San Martin de los Andes</p>
                    <p>2 & 1/2 hours from Bariloche</p>
                </div>

                <div></div>

                <div className="River_1Description_SectionTwo_Section">
                    <h3>Other Activities</h3>
                    <div className="River_1Description_Programs">
                        <div>
                            <img src={LogoTreking} alt="" />
                            <p>Trekking</p>
                        </div>
                        <div>
                            <img src={LogoBinoculares} alt="" />
                            <p>bird watching</p>
                        </div>
                        <div>
                            <img src={LogoCabalgata} alt="" />
                            <p>horse back riding</p>
                        </div>
                        <div>
                            <img src={LogoMore} alt="" />
                            <p>More...</p>
                        </div>
                    </div>
                </div>

                {/* <div className="River_1Description_SectionTwo_Section">
                    <h3>Extra</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad ut, dolores ipsa porro quod possimus dicta nemo commodi labore nobis suscipit totam, alias error minima nisi accusamus rem? Quasi, cum. Molestiae nihil esse minus accusantium laborum magni voluptatibus saepe dicta, dolorem incidunt libero commodi vero perspiciatis quas rem? Laborum, iste?</p>
                </div> */}

            </div>
        </div>
    )
}