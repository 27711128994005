import React from "react";
import { Link } from "react-router-dom";
import './ListLodge.css';
import Img1 from '../../../../assets/lodges/sanhumbberto/living.jpg'
import Img2 from '../../../../assets/lodges/tresRios/living_2.jpg'
import Img3 from '../../../../assets/lodges/collon_cura/outside_2.jpg'
import Img4 from '../../../../assets/lodges/tipileuken/outside_4.jpg';
import Img5 from '../../../../assets/lodges/riverhouse/outside_2.jpg'

export default function ListLodge(){
    return(
        <div className="ListLodge_Container">
            <div className="ListLodge_TitleContainer">
                <h2>Lodges Patagonia, Argentina</h2>
            </div>
            <div className="ListLodge_ListContainer">

                <Link to='/Lodges/SanHubberto'>
                    <div className="ListLodge_TitleContent">
                        <img src={Img1} alt="Lodge x" />
                        <h2>SAN HUBBERTO</h2>
                        <div className="ListLodge_Line"></div>
                        <p>See more information...</p>
                        <div className="ListLodge_Hover">
                                <i class='bx bx-link-alt'></i>
                        </div>
                    </div>
                </Link>
                
                <Link to='/Lodges/TresRios'>
                    <div className="ListLodge_TitleContent">
                        <img src={Img2} alt="Lodge x" />
                        <h2>tres rios</h2>
                        <div className="ListLodge_Line"></div>
                        <p>See more information...</p>
                        <div className="ListLodge_Hover">
                            <i class='bx bx-link-alt'></i>
                        </div>
                    </div>
                </Link>

                <Link to='/Lodges/CollonCura'>
                    <div className="ListLodge_TitleContent">
                        <img src={Img3} alt="Lodge x" />
                        <h2>Collon Cura</h2>
                        <div className="ListLodge_Line"></div>
                        <p>See more information...</p>
                        <div className="ListLodge_Hover">
                            <i class='bx bx-link-alt'></i>
                        </div>
                    </div>
                </Link>

                <Link to='/Lodges/Tipiliuke'>
                    <div className="ListLodge_TitleContent">
                        <img src={Img4} alt="Lodge x" />
                        <h2>Tipiliuke</h2>
                        <div className="ListLodge_Line"></div>
                        <p>See more information...</p>
                        <div className="ListLodge_Hover">
                            <i class='bx bx-link-alt'></i>
                        </div>
                    </div>
                </Link>
                
                <Link to='/Lodges/RiverHouse'>
                    <div className="ListLodge_TitleContent">
                        <img src={Img5} alt="Lodge x" />
                        <h2>River House</h2>
                        <div className="ListLodge_Line"></div>
                        <p>See more information...</p>
                        <div className="ListLodge_Hover">
                            <i class='bx bx-link-alt'></i>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    )
}